#home-hero-content {
	width: 100%;
}

#home-hero-content > *:not(div) {
	margin: 0 auto;
	display: block;
	width: -moz-fit-content;
	width: fit-content;
}

.vartical-card {
	border-radius: 20px;
	border: 1px solid var(--border);
	overflow: hidden;
	width: 300px;
}

.vartical-card img {
	aspect-ratio: 6/5;
	object-fit: cover;
	width: 100%;
}

#home .vartical-card img {
	aspect-ratio: 3/2;
}

.vartical-card section {
	aspect-ratio: 6/5;
}

.vartical-card > div {
	padding: 0 20px;
}

.vartical-card .card-title {
	font-weight: 500;
	margin: 10px 0;
}
.vartical-card .card-desc {
	margin-bottom: 20px;
}
.vertical-cards-wrapper {
	display: flex;
	align-items: center;
	gap: 18px;
	margin: 35px 0;
	overflow: auto;
}

.vertical-cards-wrapper::-webkit-scrollbar {
	width: 0;
	height: 0;
}

#popular-locations h2 {
	text-align: center;
}

/* #localities-wrapper > div {
	display: flex;
	justify-content: center;
	gap: 20px;
	margin-bottom: 20px;
} */

#localities-wrapper {
	width: 100%;
	margin: 20px auto;
	display: flex;
	flex-wrap: wrap;
	gap: 18px;
	justify-content: center;
}

.location-card {
	padding: 10px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	max-width: 250px;
	border: 1px solid var(--border);
	border-radius: 5px;
	text-align: left;
	gap: 18px;

	.business-locality {
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 26vw;
		max-width: 200px;
		overflow: hidden;
	}
}

#localities-wrapper > .location-card:last-child {
	padding: 15px 25px 15px 20px;
}

#get-app {
	padding: 67px 0 39px 0;
	background: var(--neon-light);
	margin: 100px auto 50px;
}

#get-app .container {
	display: flex;
	gap: 100px;
}

#get-app #content > *:nth-child(even) {
	margin: 20px 0;
}

.radios {
	display: flex;
	gap: 100px;
}

.radios > div {
	display: flex;
	align-items: center;
	gap: 10px;
}

#get-app #screenshots img {
	height: 360px;
}

#download-links {
	display: flex;
	gap: 10px;
}

.featured-locality {
	display: block;
	height: 3rem;
	overflow: hidden;
	-webkit-line-clamp: 2;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	white-space: normal;
}

@media screen and (max-width: 1024px) and (min-width: 600px) and (min-width: 600px) {
	.vertical-cards-wrapper {
		gap: 2vw;
		margin: 5vw 0;
	}

	#get-app {
		margin: 10vw 0 5vw;
	}

	#get-app .container {
		gap: 5vw;
	}

	#get-app #content > *:nth-child(even) {
		margin: 2vw 0;
	}

	#footer-links-wrapper {
		gap: 10vw !important;
	}

	#get-app #screenshots img {
		height: 36vw;
	}

	.radios {
		gap: 10vw;
	}

	.download-link {
		width: 13.7vw;
	}

	#download-links img {
		width: 100%;
		height: auto;
		object-fit: contain;
	}

	#home-hero-content > img {
		width: 31.2vw;
	}
}

@media screen and (max-width: 600px) {
	#home .hero-text {
		text-align: center;
	}
	#get-app {
		padding: 20px;
		margin: 80px 0 25px;
	}
	#get-app .container {
		flex-wrap: wrap;
		gap: 30px;
		justify-content: center;
	}
	#get-app #content > *:nth-child(even) {
		gap: 10px;
	}
	/* #localities-wrapper > div {
		flex-wrap: wrap;
	} */
	.vertical-cards-wrapper {
		margin: 50px 0;
		display: flex;
		gap: 25px;
		flex-direction: column;
		align-items: center;
	}
	.vartical-card {
		width: 100%;
		max-width: 380px;
	}
	.vartical-card img {
		aspect-ratio: 2/1;
		object-fit: cover;
		width: 100%;
	}
	.vartical-card .card-title {
		margin: 5px 0 2px;
	}
	.vartical-card .card-desc {
		margin-bottom: 15px;
	}
	#home-hero-content > img {
		width: 80%;
		margin: 15px auto;
	}
	#get-app #screenshots img {
		height: 50vh;
	}
	#content > div:nth-child(5) {
		display: none;
	}
}
