@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

html,
body {
	box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
img,
p,
button,
input,
span,
textarea,
a {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif !important;
	color: var(--black);
	-webkit-tap-highlight-color: transparent;
}

input,
button,
textarea {
	outline: none;
	border: none;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea {
	opacity: 1 !important;
	font-size: 1rem;
	width: 100%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

textarea {
	resize: none;
}

button {
	cursor: pointer;
	background: none;
}

a {
	opacity: 1 !important;
	display: inherit;
	text-decoration: none;
}

label {
	width: fit-content;
	cursor: pointer;
}

hr {
	border: none;
	background-color: var(--border);
	height: 1px;
	padding: 0;
	border-radius: 10px;
	margin: 0 auto;
}

*:disabled {
	cursor: not-allowed;
}

:root {
	--black: #333;
	--gray: #7c7c7c;
	--border: #cdcdcd;
	--neon-light: #ebf6cc;
	--neon: #9cd400;
	--danger: #ff5722;
}

#App,
.overlay {
	max-width: 1440px;
	margin: auto;
	position: relative;
}

.visible {
	opacity: 1 !important;
}

.white-font {
	color: #fff;
}

.theme-font {
	color: var(--neon);
}

.danger-font {
	color: var(--danger);
}

.underline {
	text-decoration: underline;
}

.anchor-tag {
	color: #fff;
	text-transform: lowercase;
	text-decoration: underline;
}

.top-content {
	background-image: url("/public/images/top-background.jpg");
	background-position: 50% 50%;
	background-size: cover;
	height: 72vh;
	max-height: 550px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.pwu .top-content {
	height: 90vh;
}

nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	margin: auto;
}

nav img {
	width: 180px;
}

#navlinks-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 26px;
	& a:hover {
		text-decoration: underline;
	}
}

.navlink-dropdown {
	position: relative;
	.trigger {
		display: flex;
		align-items: center;
		gap: 10px;
		& > span {
			opacity: 1;
		}
	}
	.dropdown {
		z-index: 1;
		position: absolute;
		margin-top: 5px;
		border-radius: 5px;
		padding: 8px;
		background: white;
		box-shadow: 0 0 10px -5px;
		& a {
			padding: 3px 0;
			color: var(--black);
		}
	}
}

#mwrpr .dropdown {
	left: calc(-100% - 15px - 16px - 2px);
	top: 0;
}

nav :is(a, button, button > span) {
	opacity: 1 !important;
	white-space: nowrap;
	cursor: pointer;
	color: #ffffff;
	text-transform: capitalize;
}

#hbmenu-wrapper {
	display: none;
	position: relative;
}

#mtoggle-btn {
	display: flex;
	background: none;
}

#mtoggle-btn svg {
	fill: white;
}

#mwrpr {
	position: absolute;
	right: 10px;
	white-space: nowrap;
	display: flex;
	flex-direction: column;
	gap: 10px;
	text-decoration: none;
	font-size: 14px;
	background: white;
	padding: 15px;
	border-radius: 8px;
	min-width: 100px;
	box-shadow: 0 0 10px -5px;
	z-index: 1;
}

#mwrpr :is(a, button, span) {
	color: black;
	opacity: 1;
	text-align: left;
}

.heading {
	font-size: 30px;
	font-weight: 600;
	line-height: normal;
	text-transform: capitalize;
}

.font-2xl {
	font-size: 36px;
}

.font-xl {
	font-size: 30px;
}

.subheading {
	font-size: 22px;
	font-weight: 600;
	line-height: normal;
	text-transform: capitalize;
}

.font-l {
	font-size: 22px;
}

.font-m {
	font-size: 18px;
}

.font-md {
	font-size: 16px;
}

.font-s {
	opacity: 0.7;
	font-size: 15px;
}

.font-xs {
	opacity: 0.7;
	font-size: 14px;
}

.hidden-input-checkbox {
	pointer-events: none;
	position: absolute;
	opacity: 0;
	top: 25%;
	left: 25%;
}

.see-more {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 18px;
	width: 100%;
}

.radio {
	fill: var(--border);
	stroke: var(--border);
}

.radio.active {
	fill: var(--neon);
	stroke: var(--neon);
}

.relative {
	position: relative;
}

.absolute {
	position: absolute !important;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.text-right {
	text-align: right;
}

.text-ellipse {
	--lines: 2;
	display: -webkit-box;
	-webkit-line-clamp: var(--lines);
	text-overflow: ellipsis;
	overflow: hidden;
	max-height: 3rem;
	-webkit-box-orient: vertical;
}

.flex {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.no-gap {
	gap: 0 !important;
}

.flex.gap-m {
	gap: 10px !important;
}

.flex.wrap {
	flex-wrap: wrap;
}

.flex.between {
	justify-content: space-between;
}

.flex.row {
	flex-direction: row;
}

.flex.col {
	flex-direction: column;
}

.flex.row.start {
	justify-content: flex-start;
}

.flex.col.start {
	align-items: flex-start;
}

.gap-xs {
	gap: 5px;
}

.mb {
	margin-bottom: 5px;
}

.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}

.error-line {
	background: #ff00001f;
	border-radius: 6px;
	padding: 2px 10px;
	margin-top: 5px;
	text-align: left;
}

.theme-input,
.theme-btn,
.select {
	padding: 15px 20px;
	border-radius: 5px;
	white-space: nowrap;
	background: #fff;
	border: 1px solid var(--border);
}

.input-action-wrapper {
	position: relative;
}

.input-action-wrapper button {
	position: absolute;
	top: 50%;
	right: 6%;
	translate: 0 -50%;
	display: flex;
	opacity: 0.7;
}

.input-action-wrapper svg {
	width: 20px;
	height: 20px;
}

.theme-input,
.theme-btn {
	opacity: 1;
}

.theme-btn.small {
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 8px 12px;
	background: var(--neon-light);
	border-radius: 3px;
}

.theme-btn {
	gap: 10px;
	text-transform: capitalize;
}

.theme-btn.primary {
	overflow: hidden;
	background: var(--neon);
	color: #fff;
}

.theme-btn.secondary {
	background: var(--neon-light);
}

.theme-btn.grey {
	background: #e3e3e3;
	color: var(--gray);
}

.theme-btn.outlined {
	border: 1px solid var(--gray);
	background: transparent;
}

.theme-btn.outlined.theme-font {
	border-color: var(--neon);
	font-weight: 600;
}

.theme-btn.danger {
	background: var(--danger) !important;
	color: white !important;
}

.theme-btn.danger.theme-btn.outlined {
	border-color: var(--danger) !important;
	background: transparent !important;
	color: var(--danger) !important;
	font-weight: 600;
}

.theme-btn.danger.theme-btn.outlined > svg {
	fill: var(--danger);
}

.theme-btn.danger * {
	color: white;
}

.theme-btn.danger svg {
	fill: white;
}

.theme-input.bottom-border {
	background: no-repeat;
	border: none;
	padding: 0;
	width: 5rem;
	border-bottom: 1px solid;
	border-radius: 0;
	padding: 0 9px;
}

.section {
	border-radius: 15px;
	border: 0.5px solid var(--border);
	margin: 36px 0;
	padding: 20px 26px;
}

.image-modal .section,
.modal {
	background: #fff;
	width: 80%;
	overflow: auto;
}

.image-modal .theme-gallery {
	height: 56vh;
	overflow: auto;
	gap: 10px;
}

.image-modal .theme-gallery > div {
	width: calc(33% - 6.66px);
	height: fit-content;
}

.section.upload-image > div:first-child {
	display: flex;
	justify-content: space-between;
}

.section.shadow {
	box-shadow: 0px 8px 8px 0px rgba(156, 212, 0, 0.2);
}

.dashed-heading {
	display: flex;
	align-items: center;
	gap: 20px;
}

.dashed-heading hr {
	width: 100%;
	opacity: 0.4 !important;
	border: none;
	height: 1px;
	background: var(--gray);
	border-radius: 5px;
}

.dashed-heading span {
	white-space: nowrap;
	text-transform: capitalize;
}

.radio-wrapper {
	display: flex;
	gap: 10px;
	align-items: center;
}

.modal .urls-wrapper {
	height: 50vh;
	overflow: auto;
}

.modal button.form-submit {
	margin: 16px auto;
}

.urls-wrapper .input-action-wrapper button {
	right: 0;
}

.urls-wrapper hr {
	margin: 15px auto;
}

.urls-wrapper > .content {
	margin: 24px 0;
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
}

.urls-wrapper > .content > div {
	width: calc(50% - 50px);
}

.urls-wrapper > .content input {
	width: -webkit-fill-available;
}

.guide-link {
	width: calc(50% - 10px) !important;
	border: 1px solid var(--border);
	padding: 16px 0;
	border-radius: 1rem;
	svg {
		flex-shrink: 0;
		color: var(--neon);
	}
	> * {
		padding: 0 16px;
	}
	> *:nth-child(2) {
		margin: 0;
		padding: 12px 16px;
		border-top: 1px solid var(--border);
		border-bottom: 1px solid var(--border);
		margin: 12px 0;
	}
	a {
		max-width: calc(100% - 36px);
	}
	a:hover {
		text-decoration: underline;
	}
	> div:first-child,
	p {
		text-transform: capitalize;
	}
	div {
		gap: 10px;
	}
}

.services-wrapper > label {
	display: flex;
	align-items: center;
	gap: 10px;
	margin: 8px 0;
}

.checkbox.checked {
	fill: var(--neon);
}

.static-page {
	p.quote.flex {
		margin: 40px 0;
	}
	a.redirect-anchor {
		text-decoration: underline;
		display: inline;
		font-weight: bold;
	}
	.subheading {
		margin-top: 60px;
		text-transform: capitalize;
	}
	.highlighted {
		padding: 1rem 1.2rem;
		background: var(--neon-light);
		margin: 40px 0;
		border-radius: 5px;
	}
	.content {
		margin: 50px auto 80px;
		& > p {
			margin: 20px 0;
		}
	}
	hr {
		margin: 20px 0 30px;
		display: block;
		background: var(--neon);
		height: 1px;
	}
}

footer {
	position: relative;
	text-transform: capitalize;
}

footer ul {
	list-style-type: none;
	padding: 0;
	margin: 25px 0;
}

footer li {
	margin: 8px 0;
}

footer a:hover li {
	text-decoration: underline;
}

#footer-links-wrapper {
	display: flex;
	margin: 20px 0;
	gap: 120px;
}

footer #social-links > div:nth-child(2) {
	gap: 15px;
	width: fit-content;
	margin: 20px 0 15px;
}

footer #social-links > div:nth-child(3) {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

* > .hero-text,
.hero-text > * {
	color: #fff !important;
}

div > .hero-text {
	font-size: 32px;
	font-weight: 500;
	text-transform: capitalize;
}

/* div > .hero-text.welcome-user {
} */

.page-wrapper {
	width: calc(100% - 40px);
	min-height: calc(100vh - 479px);
	margin: auto;
}

.page-wrapper #navlinks-container a,
.page-wrapper #navlinks-container button,
.page-wrapper #navlinks-container span {
	color: var(--black);
}

.page-wrapper #hbmenu-wrapper button svg {
	fill: var(--black);
}

.page-wrapper nav {
	padding: 20px 0;
	width: 100%;
	position: unset;
}

.page-wrapper nav #master-search {
	margin-top: 0;
	box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.08);
}

.container {
	position: relative;
	width: calc(100% - 150px);
	height: 100%;
	margin: auto;
}

#master-search {
	width: 80%;
	max-width: calc(1440 * 0.45 * 1px);
	display: flex;
	background: #fff;
	border-radius: 12px;
	margin: 20px auto 0;
	position: relative;

	& > div {
		padding: 14px 20px;
		display: flex;
		align-items: center;
		width: 100%;
	}
	#location-select {
		width: 60%;
		border-radius: 12px;
		border: none;
		.select-dropdown button {
			font-size: 0.9rem;
		}
	}
	.divider {
		background: var(--black);
		border-radius: 10px;
		width: 1px;
		padding: 0;
		margin: 8px 0;
		opacity: 0.5;
	}
	& > div:nth-child(3) > input {
		padding-left: 20px;
		background: none;
	}
	& div > #location-select {
		border: none;
		width: 70%;
		background: transparent;
	}
	& div > #location-select > span {
		opacity: 0.65;
	}
	#search-results {
		box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.2);
		position: absolute;
		top: 100%;
		background: white;
		margin-top: 2px;
		border-radius: 12px;
		z-index: 100;
		padding: 0;
		display: block;

		& > div {
			display: block;
			height: 250px;
			overflow: auto;
		}
		& table {
			width: 100%;
			border-collapse: collapse;
		}
		& tr {
			border-bottom: 1px solid var(--border);
			cursor: pointer;
		}
		& td {
			padding: 10px 20px;
		}
		& td:first-child {
			width: 100%;
		}
	}
}

.select {
	gap: 10px;
	position: relative;
}

.select > div:first-child {
	width: 100%;
}

.select > div:first-child > span {
	width: 100%;
	display: block;
	user-select: none;
	text-overflow: ellipsis;
	overflow: hidden;
}

.select > div:first-child > span.no-value {
	opacity: 0.7;
}

.select.top .select-dropdown {
	top: 105%;
}

.select.bottom .select-dropdown {
	bottom: 100%;
	translate: 0 -5%;
}

.select-dropdown {
	box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.08);
	width: calc(100% - 40px);
	padding: 10px 20px;
	border-radius: 15px;
	position: absolute;
	background: white;
	display: flex;
	flex-direction: column;
	gap: 8px;
	z-index: 1;
	max-height: calc(250px - 20px);
	overflow: auto;
	left: 0;

	& button {
		color: var(--black);
		background: none;
		font-size: 1rem;
		padding: 3px 0;
		text-align: left;
	}
}

.footer-content-wrapper {
	padding: 80px 30px 30px;
	border-top: 1px solid var(--border);
	width: fit-content;
	margin: auto;
}

.footer-content-wrapper::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	display: block;
	height: 1px;
	background: var(--border);
	width: 100%;
}

.overlay {
	position: fixed;
	top: 50%;
	left: 50%;
	translate: -50% -50%;
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.6);
	overflow: hidden;
	z-index: 100;
}

.prompt {
	background: white;
	padding: 20px 26px;
	overflow: hidden;
	border-radius: 12px;
	min-width: 50vw;
	max-width: 800px;
}

.prompt h2 {
	margin-bottom: 6px;
}

.prompt .obj-info {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
}

.prompt .modal-actions {
	margin-top: 30px;
	gap: 10px;
	justify-content: flex-end;
}

.slider-wrapper {
	position: relative;
	#slides-container {
		width: 100vw;
		height: 100vh;
		overflow: hidden;

		& > div {
			width: 100%;
			height: 100%;
			position: relative;
			display: flex;
			transition: all 200ms ease;
		}

		img {
			user-select: none;
			object-fit: contain;
			min-width: 100%;
			min-height: 100%;
			max-width: 100%;
			max-height: 100%;
		}
	}
	.dir-btns-wrapper {
		position: absolute;
		bottom: 10px;
		display: flex;
		gap: 10px;
		left: 50%;
		translate: -50%;
		margin-top: 20px;
		z-index: 1;

		button {
			background: rgba(0, 0, 0, 0.3);
			border: 1px solid rgb(123, 123, 123);
			border-radius: 50%;
			aspect-ratio: 1;
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				width: 25px;
				stroke: white;
				fill: white;
			}
		}
	}
}

.theme-gallery {
	--gap: 10;
	--image-per-row: 4;
	--gap-space: calc(var(--gap) * (var(--image-per-row) - 1));
	--gap-per-image: calc((var(--gap-space) / var(--image-per-row)));

	display: flex;
	flex-wrap: wrap;
	gap: calc(var(--gap) * 1px);
	margin: 20px 0;
}

#gallery-page {
	--col: 3;
	--card-gap: 20;
	--image-gap: 10;
	--card-width: calc((100 / var(--col) * 1%) - (((var(--card-gap) * (var(--col) - 1)) / var(--col)) * 1px) - 2px);
	--image-width: calc((100 / var(--col) * 1%) - (((var(--image-gap) * (var(--col) - 1)) / var(--col)) * 1px));
	padding-bottom: 50px;
	.album:hover,
	.album-view {
		box-shadow: 0px 6px 18px 2px #0000002f;
	}
	#gallery-content {
		margin: 36px auto 64px;
	}
	.image-container {
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		aspect-ratio: 5/4;
		width: var(--image-width);
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	#albums-container {
		gap: calc(var(--card-gap) * 1px) !important;
	}
	.album {
		width: var(--card-width);
		overflow: hidden;
		transition: all 300ms ease;
		border: 1px solid var(--border);
		border-radius: 10px;
		align-self: stretch;
		justify-content: flex-start;
		.cover-image {
			width: 100%;
			align-self: stretch;
			.album-icon {
				position: absolute;
				font-size: 32px;
				top: 6%;
				left: 6%;
				padding: 3px 5px;
				border-radius: 5px;
				color: white;
				background: #00000082;
			}
		}
		.album-content {
			padding: 12px 25px 25px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-self: stretch;
			.actions-wrapper {
				margin-top: 10px;
			}
		}
	}
	.album-view {
		overflow: hidden;
		border-radius: 8px;
		padding: 26px;
		h6 {
			margin: 30px 0 10px;
		}
		.grid {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			gap: calc(var(--image-gap) * 1px) !important;
		}
		.back-btn {
			gap: 5px !important;
			margin-bottom: 10px;
			padding: 5px 12px 5px 8px;
			border-radius: 2rem;
			transition: all 300ms ease;
			&:hover {
				background: rgba(0, 0, 0, 0.08);
			}
		}
	}
}

.theme-gallery > div {
	width: calc((100% / var(--image-per-row)) - (var(--gap-per-image) * 1px));
	border-radius: 10px;
	overflow: hidden;
	aspect-ratio: 5/4;
	display: block;
	position: relative;
	cursor: pointer;
}

.theme-gallery > div > img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

.selection {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
}

.highlight {
	position: absolute;
	border-radius: 1rem;
	right: 10%;
	bottom: 10%;
	background: var(--black);
	min-width: 15%;
}

button.form-submit {
	font-size: 1rem;
	margin: 50px auto 80px;
	width: 40%;
	border-radius: 2rem;
}

.authPage {
	& {
		padding: 24px;
		background: #fff;
		border-radius: 10px;
		width: 50vw;
		max-width: 420px;
	}

	& > div:first-child svg {
		width: 24px;
		height: 24px;
	}

	.theme-btn,
	.theme-input {
		width: 100%;
	}

	.input-fields {
		margin: 24px 0;
		max-height: 62.5vh;
		overflow: auto;
		display: flex;
		flex-direction: column;
		gap: 12px;
	}

	.input-fields > input.theme-input {
		display: block;
		width: -webkit-fill-available;
	}

	.dashed-heading {
		margin: 16px 0;
	}

	.theme-input.outlined {
		margin: 10px 0;
	}

	.redirect {
		margin-top: 24px;
	}

	.redirect * {
		opacity: 1;
		margin-right: 10px;
	}
}

.mnum-field {
	display: flex;
	align-items: stretch;
}

.mnum-field > div:first-child {
	border: 1px solid var(--border);
	display: flex;
	align-items: center;
	border-radius: 6px;
}

.mnum-field .input {
	display: flex;
	border: 1px solid var(--border);
	border-radius: 6px;
	overflow: hidden;
	width: 100%;
}

.mnum-field .input > * {
	padding: 14px 20px;
}

.mnum-field .input span {
	border-right: 1px solid var(--border);
}

.mnum-field button {
	width: 30%;
	padding: 0;
}

div.otp_input.flex {
	gap: 5px !important;
	margin: 10px 0;
}

.otp_input input.theme-input.outlined {
	margin: 0;
	border: 2px solid var(--border);
	text-align: center;
	border-radius: 8px;
	aspect-ratio: 0.75;
}

.otp_input input.theme-input.outlined::placeholder {
	opacity: 0.4;
}

.otp_input input.theme-input.outlined:focus {
	border: 2px solid var(--gray);
}

.otp-info button {
	margin-left: 5px;
	text-decoration: underline;
}

.otp-info.resend {
	text-align: right;
}

#get-premium {
	& {
		padding: 30px 40px;
		border-radius: 20px;
		width: 40%;
		max-width: calc(450px - 80px);
		position: relative;
	}

	& #close {
		position: absolute;
		top: 30px;
		right: 30px;
		width: 24px;
	}

	& .subheading {
		text-align: center;
		margin-bottom: 10px;
	}

	& p {
		text-align: center;
	}

	#premium-logo {
		position: relative;
		margin: 20px auto 40px;
		width: fit-content;
	}

	.email-wrapper {
		/* display: flex;
		align-items: center;
		justify-content: center; */
		margin: 20px 0;
		input {
			width: -webkit-fill-available;
			text-align: center;
		}
		div.theme-input {
			border-color: transparent;
			width: fit-content;
			padding-left: 0;
			padding-right: 10px;
			margin-left: 30px;
		}
		button {
			padding: 7px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid transparent;
		}
		button:hover {
			border-color: #33333330;
			background: #33333314;
			border-radius: 50%;
			aspect-ratio: 1;
			height: auto;
		}
		button > svg {
			font-size: 20px;
			color: currentColor;
		}
	}

	& > button.theme-btn.form-submit {
		background: var(--black);
		color: #fff;
		margin: 0 auto;
		display: block;
		padding: 18px 40px;
		width: 70%;
	}

	& > button.theme-btn.form-submit {
		background: var(--black);
		color: #fff;
		margin: 0 auto;
		display: block;
		padding: 18px 40px;
		width: 70%;
	}

	& .loader.progress-bar {
		background: var(--black);
	}

	div:has(> .payment-success-check) {
		display: flex;
	}

	div:has(> .payment-success-check)::after {
		content: "";
		background: #4caf4f6e;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		aspect-ratio: 1;
		animation: scaleAndFade 2.5s infinite;
	}

	.payment-success-check,
	.payment-failed-icon {
		border-radius: 50%;
		background: #4caf50;
		padding: 25px;
		fill: white;
		z-index: 1;
	}
	& > button.theme-btn.form-submit.success {
		background: #4caf50;
	}

	.payment-failed-icon {
		background: #ff9800;
	}
	& > button.theme-btn.form-submit.failed {
		background: #ff9800;
		& .loader.progress-bar {
			background: #ff9800;
		}
	}
}

@keyframes scaleAndFade {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	100% {
		transform: scale(1.75);
		opacity: 0;
	}
}

#user-profile .input-fields .mnum-field {
	gap: 10px;
	.input + button {
		width: fit-content;
		padding: 0 25px;
	}
}

.spinner-wrapper {
	width: 100%;
	height: 100%;
	background: rgba(255, 255, 255, 0.799);
	&.absolute {
		top: 0;
		left: 0;
	}
	&.transparent {
		background: transparent;
	}
}

#blog-cover-wrapper {
	margin-bottom: 2rem;
	width: 100%;
	overflow: hidden;
	border-radius: 1rem;
	aspect-ratio: 3;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.quill {
	.ql-editor {
		line-height: 1.6;
		padding: 0;
		a {
			display: inline-block;
		}
	}
	.ql-container {
		font-size: 1.05rem;
		margin-bottom: 2rem;
		border: none;
	}
}

.ncards-wrapper {
	margin: 3rem 0;
	.ncard {
		border-radius: 15px;
		background: #fff;
		display: flex;
		margin-bottom: 25px;
		overflow: hidden;
		border: 0.1px solid #7c7c7c11;
		box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.12);

		.subheading {
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 47vw;
			margin: 0;
		}

		.ncard-content {
			height: calc(100% - 30px);
			width: fit-content;
			padding: 10px 26px 20px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.ncard-img {
			aspect-ratio: 2;
			width: 50%;
		}

		.ncard-img img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			display: block;
		}

		p {
			margin: 5px 0 15px;
		}

		.ndetails {
			display: flex;
			align-items: center;
			gap: 10px;
			opacity: 0.9;
			margin-bottom: 5px;
		}

		a {
			text-decoration: underline;
		}
	}
}

.theme-btn .loader.progress-bar {
	width: 90%;
	background: var(--neon);
	height: 100%;
}

.loader.progress-bar {
	width: 100%;
	height: 4.8px;
	display: inline-block;
	position: relative;
	background: transparent;
	overflow: hidden;
	&::after {
		content: "";
		width: 192px;
		height: 4.8px;
		background: #fff;
		position: absolute;
		top: 50%;
		left: 0;
		translate: 0 -50%;
		box-sizing: border-box;
		animation: progress-bar 2s linear infinite;
	}
}

.loader.pulse {
	width: 56px;
	height: 56px;
	display: inline-block;
	position: relative;
	&::after,
	&::before {
		content: "";
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: var(--gray);
		position: absolute;
		opacity: 0;
		left: 0;
		top: 0;
		animation: animloader 2s linear infinite;
	}
	&::after {
		animation-delay: 1s;
	}
}

.loader.spinner {
	width: 36px;
	height: 36px;
	border: 5px solid var(--border);
	border-bottom-color: #ff3d00;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

.copy-button:active {
	scale: 0.8;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animloader {
	0%,
	5% {
		transform: scale(0);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
}

@keyframes progress-bar {
	0% {
		left: 0;
		transform: translateX(-100%);
	}
	100% {
		left: 100%;
		transform: translateX(0%);
	}
}

@media screen and (max-width: 1024px) and (min-width: 600px) {
	.page-wrapper {
		width: calc(100% - 24px);
	}
	.container {
		width: calc(100% - 12vw);
	}
	.pwu .top-content {
		height: 54vw;
	}
	div > .hero-text {
		font-size: 3.2vw;
	}
	.heading {
		font-size: 3vw;
	}
	.subheading,
	.font-l {
		font-size: 2.2vw;
	}
	.font-m {
		font-size: 1.8vw;
	}
	.font-md {
		font-size: 1.7vw;
	}
	.font-s {
		font-size: 1.6vw;
	}
	.font-xs {
		font-size: 1.4vw;
	}
	.theme-input,
	.theme-btn,
	.select {
		padding: 1.2vw 2vw;
		font-size: 1.4vw;
	}
	.flex {
		gap: 1.5vw !important;
	}
	.flex.no-gap {
		gap: 0 !important;
	}
	.social-networks img {
		width: 3.5vw;
	}
	small {
		font-size: 1.4vw;
	}
	#master-search {
		width: 100%;
		margin-top: 15px;
	}
	#master-search > div {
		padding: 12px;
	}
	#master-search #divider {
		margin: 8px 0;
	}
	#master-search > div:nth-child(3) > input {
		padding-left: 8px;
	}
	.mnum-field .input > * {
		padding: 10px 16px;
	}
	nav {
		gap: 3.5vw;
		padding: 1.8vw 0;
	}
	div > nav img {
		width: 22vw;
	}
	nav #navlinks-container {
		gap: 2vw;
	}
	nav #navlinks-container a,
	nav #navlinks-container button {
		font-size: 1.6vw;
	}
	.pwu #btns-container {
		flex-wrap: wrap;
		gap: 5px !important;
	}
	.theme-gallery {
		--gap: 10;
	}
	.section {
		padding: 15px;
	}
	#gallery-page {
		--card-gap: 14;
		.album-view {
			padding: 2.2vw;
		}
		.album {
			.album-content {
				padding: 1vw 2vw 2vw;
			}
		}
	}
}

@media screen and (max-width: 600px) {
	#gallery-page {
		--card-gap: 14;
		--col: 2;
		.album-view {
			padding: 2.6vw;
			.grid {
				--gap: 5;
			}
		}
		.album {
			.album-content {
				padding: 1vw 2vw 2vw;
			}
		}
	}
	.top-content {
		height: 90vw;
	}
	nav img,
	footer img[alt="Sharanyu"] {
		width: 135px !important;
	}
	.static-page {
		.content {
			margin: 15px auto 50px;
			& > p {
				margin: 10px 0;
			}
		}
		hr {
			margin: 10px 0 15px;
		}
		.subheading {
			margin-top: 30px;
		}
	}
	#hbmenu-wrapper button svg {
		width: 24px;
		height: 24px;
	}
	.dir-btns-wrapper {
		margin-top: 10px;
	}
	.container {
		width: 100%;
	}
	div > nav #navlinks-container {
		display: none;
	}
	div > nav #master-search {
		display: none;
	}
	div > nav #hbmenu-wrapper {
		display: block;
	}
	div > .hero-text {
		font-size: 24px;
	}
	.heading {
		font-size: 20px;
	}
	.subheading,
	.font-l {
		font-size: 18px;
	}
	.font-m {
		font-size: 16px;
	}
	.font-md {
		font-size: 15px;
	}
	.font-s {
		font-size: 14px;
	}
	.font-xs {
		font-size: 12px;
	}
	.theme-input,
	.theme-btn,
	.select {
		padding: 12px 14px;
	}
	input,
	.theme-input,
	.theme-btn,
	.select {
		font-size: 13px;
	}
	#master-search {
		width: 100%;
	}
	.prompt {
		padding: 18px;
		width: calc(92% - 36px);
	}
	.section {
		margin: 30px 0;
		padding: 16px 18px;
	}
	.section.upload-image > div:first-child {
		flex-direction: column;
		gap: 14px;
	}
	.section.upload-image button.theme-btn {
		width: 100%;
		background: none;
		border: 1px solid var(--gray);
		color: var(--black);
	}
	.section.upload-image button.theme-btn svg {
		stroke: var(--black);
	}
	.mnum-field .input > * {
		padding: 10px 16px;
	}
	.theme-gallery > div {
		min-width: unset;
		--image-per-row: 3;
		border-radius: 8px;
	}
	#get-premium {
		& {
			width: 100vw;
			height: 100vh;
			max-width: 100vw;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		svg#close {
			top: 50px;
		}
	}
	#footer-links-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: space-between;
	}
	footer #social-links {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
	}
	footer .social-networks a {
		width: 24px;
		height: 24px;
	}
	footer #social-links > div:first-child {
		display: none;
	}
	footer #social-links > div:nth-child(2) {
		margin: 0;
	}
	footer #social-links > div:nth-child(3) {
		display: flex;
		/* width: 60%; */
		gap: 10px;
		flex-direction: row;
		justify-content: space-between;
	}
	footer #social-links > div:nth-child(3) a {
		width: auto;
		margin-top: 20px;
	}
	footer #social-links > div:nth-child(3) img {
		width: 100%;
	}
	footer .font-m {
		font-size: 14px;
	}
	footer .font-s {
		font-size: 12px;
	}
	footer ul {
		margin: 15px 0;
	}
}

@media screen and (max-width: 500px) {
	#blog-cover-wrapper {
		aspect-ratio: 2;
	}
	#blogs {
		div:has(> .search-bar) {
			width: 100%;
			.search-bar {
				width: 100%;
			}
		}
		.ncards-wrapper {
			margin: 1.25rem 0;
			.ncard-content {
				padding: 0.5rem 0.8rem 0.8rem !important;
			}
			.ncard {
				p {
					margin-bottom: 0.5rem;
				}
				.ncard-img {
					aspect-ratio: 1;
				}
			}
		}
	}
	#gallery-page {
		--col: 2;
		--card-gap: 14;
		.album {
			.album-content {
				padding: 1vw 2vw 2vw;
			}
		}
	}
	.authPage {
		width: 80vw;
	}
	.flex {
		gap: 10px;
	}
	.flex.no-gap {
		gap: 0;
	}
	.theme-gallery > div {
		--image-per-row: 2;
	}
	.heading {
		font-size: 18px;
	}
	.subheading,
	.font-l {
		font-size: 16px;
	}
	.font-m {
		font-size: 14px;
	}
	.font-md {
		font-size: 13px;
	}
	.font-s {
		font-size: 12px !important;
	}
	.font-xs {
		font-size: 11px;
	}
}

@media screen and (max-width: 425px) {
	#gallery-page {
		--col: 2;
		--card-gap: 8;
		.album-view {
			padding: 2.6vw;
			.grid {
				--gap: 5;
			}
		}
		.album {
			.cover-image .album-icon {
				font-size: 24px;
			}
			.album-content {
				padding: 1vw 2vw 2vw;
				> div > div:first-child span {
					font-size: small;
				}
				> div > div:last-child {
					display: none;
				}
			}
		}
	}
}

@media screen and (max-width: 400px) {
	#blogs {
		.ncard-img {
			display: none;
		}
	}
}
