#business {
	width: 80vw;
	max-width: 1024px;
	margin: auto;
}

#toc {
	background: rgb(252 254 247);
	filter: drop-shadow(0px 8px 8px rgba(156, 212, 0, 0.2));
	padding: 20px;
	width: 28%;
	height: fit-content;
	border-radius: 15px;
	position: sticky;
	top: 20px;
	margin-bottom: 100px;
}

#toc h2 {
	text-align: center;
}

#toc hr {
	width: 64%;
	margin: 14px auto 20px;
}

#toc > div {
	cursor: pointer;
	display: flex;
	gap: 16px;
	align-items: flex-start;
	margin: 15px 0;
}

#toc > div .font-m {
	opacity: 0.7;
}

#toc .index {
	opacity: 0.3;
	display: flex;
	aspect-ratio: 1;
	align-items: center;
	height: 30px;
	border-radius: 20px;
	background: var(--neon);
	justify-content: center;
}

#toc .index span {
	color: #fff;
}

#toc > div.selected .font-m {
	opacity: 1;
	color: var(--neon);
}

#toc > div.selected .index {
	opacity: 1;
}

#crbusiness #container {
	display: flex;
	gap: 50px;
}

#crbusiness #container > div:last-child {
	width: 100%;
}

#crbusiness #content {
	max-width: 700px;
}

#crbusiness .section .font-md:not(button) {
	opacity: 0.7;
}

#crbusiness .section > * {
	margin-bottom: 20px;
}

.info {
	padding: 0 20px;
}

.info .font-xs,
.info .font-md {
	opacity: 1 !important;
}

.inputs-row {
	display: flex;
	gap: 20px;
}

#crbusiness .section input.theme-input {
	width: calc(100% - 40px);
}

.radio-wrapper {
	justify-content: flex-start;
}

.upload-image .inputs-row {
	margin: 20px 0;
}

#crbusiness #content {
	height: 100%;
}

#crbusiness .heading + .section {
	margin-top: 0;
}

#content .heading {
	margin: 20px 0;
}

#crbusiness form.disabled > div {
	opacity: 0.5;
}

#discount-table {
	width: 100%;
	& th {
		text-align: start;
	}
}

.business-image-placeholder {
	background: #00bf0021;
	color: #90d290;
	& svg {
		font-size: 48px;
	}
}

#delivery-charge-ranges {
	border-collapse: collapse;
	th {
		padding-bottom: 26px;
	}
	tbody > tr > td:first-child {
		width: 50%;
		span {
			width: 10.9rem;
			margin: auto;
			display: block;
		}
	}
	input.theme-input {
		padding: 10px 5px 10px 15px;
		border-width: 0;
		border-radius: 8px;
		background: #e5e6eaef;
	}
	td {
		border-bottom: 1px solid var(--border);
		padding: 10px 0;
		border-color: #e0e0e0;
	}
	tbody > tr:last-child > td {
		border-bottom: 0;
	}
}

#delivery-charge-calculator {
	& > div {
		gap: 0 !important;
	}
	& > div:nth-child(2) {
		margin-top: 20px;
	}
	& > div:nth-child(3) {
		margin: 10px 0;
	}
	.label {
		width: 9.5rem;
		display: block;
	}
}

@media screen and (max-width: 1040px) and (min-width: 700px) {
	#business {
		width: 90%;
	}
	#crbusiness #container {
		gap: 25px;
	}
	#toc {
		padding: 10px;
		width: 35%;
	}
	#toc > div {
		gap: 10px;
	}
	#toc .index {
		height: 20px;
	}
}

@media screen and (max-width: 700px) {
	#business {
		width: auto;
	}
	#business.page-wrapper {
		max-width: calc(100% - 20px);
	}
	#crbusiness #container {
		gap: 30px;
		flex-direction: column;
	}
	#toc {
		display: none;
	}
	.inputs-row {
		gap: 10px;
	}
	.info {
		padding: 0;
	}
}
