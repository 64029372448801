#product-form .container {
	margin: 50px auto;
}

#product-form .container.disabled > div:not(:first-child) {
	opacity: 0.5;
}

#product-form .container > div:first-child {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.p-fields {
	display: flex;
	justify-content: space-between;
	gap: 15px;
	margin: 30px 0;
}

.p-fields > div {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.p-fields.full > div {
	width: 100%;
}

.p-fields > div > input,
.p-fields > div > textarea {
	width: auto;
}

.p-fields label {
	margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
	#product-form .container {
		width: 100%;
		margin: 25px 0;
	}
	.p-fields {
		flex-wrap: wrap;
	}
	.p-fields > div {
		width: 100%;
	}
}
